//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import apiUser from "@/public/apiUser/apiUser";
import cookie from "@/storage/cookies.js";
export default {
  name: "Banner",
  data() {
    return {
      list: [],
      loadState: false
    };
  },
  props: {
    // list: {
    //   type: Array,
    //   default: []
    // }
  },
  computed: {
    bannerList: function() {
      return this.list;
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    //点击banner跳转
    jumpUrl(item) {
      item.url && window.open(item.url);
    },
    async init(){
      let cookieArea = cookie.cookieRead("area");
      let cookieAreaObj = cookieArea ? JSON.parse(cookieArea) : null
      let currentArea = null
      if(!cookieAreaObj){
        try {
          const resArea = await apiUser.getArea();
          if (
            resArea.data.code &&
            +resArea.data.code === 200 &&
            resArea.data.data
          ) {
            currentArea = resArea.data.data.find(item => item.checked);
          }
        } catch (error) {}
      }
      let serverCurrentArea = cookieAreaObj || currentArea;
      try {
          const { id } = serverCurrentArea;
          const parmas = {
            type: 1,
            regionId: id
          };
          const bannerRes = await apiHome.getBannerAdverList(parmas);
          const { code, data, msg } = bannerRes.data;
          if (+code === 200) {
            this.list =
              data &&
              data.map(item => {
                return {
                  bannerImg:
                    item.pic + "?x-oss-process=image/interlace,1/format,jpg",
                  url: item.url
                };
              });
          }
          this.loadState = true
        } catch (error) {
          this.loadState = true
        }
    }
  }
};
