
import {apiInquiryPrice} from '@/api/apiInquiryPrice.js'
export const fnc = {
  getProperty: getProperty
}

async function getProperty (id) {
  let property = await apiInquiryPrice.fetchProperty(id)
  let list = property.data.data

  let propertyList = [] // 商品属性列表
  for (let i = 0; i < list.length; i++) {
    if (list[i].itemparamname === '仓储') {
      continue
    }
    let flag = true
    for (let j = 0; j < propertyList.length; j++) { // 去重 合并属性名相同的项
      if (propertyList[j].name === list[i].itemparamname) {
        propertyList[j].id.push(list[i].id)
        propertyList[j].data.push(list[i].valdata)
        flag = false
        break
      }
    }
    if (flag) { // 非重复 添加商品属性和id
      let obj = {
        name: list[i].itemparamname,
        id: [],
        data: []
      }
      obj.id.push(list[i].id)
      obj.data.push(list[i].valdata)
      obj.propertyId = list[i].itemparamid
      propertyList.push(obj)
    }
  }
  return propertyList
}
